.viewer {
  display: flex;
  width: 100vw;
  height: 100vh;
  transition: transform 0.3s;
}

.viewer--annotations-open {
  width: 100vw;
  transform: translateX(0);
}

.viewer-wrapper {
  position: relative;
  flex: 1 1 auto;
}

.osd-viewer {
  position: absolute;
  height: 100%;
  width: calc(100% + 320px);
  top: 0;
  left: 0;
  z-index: 1;
  transform: translateX(-320px);
  transition: all 0.3s;
}

.viewer--annotations-open .osd-viewer {
  width: 100%;
  transform: translateX(0);
}

.storiii-info {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 10;
}

.osd-controls {
  margin-bottom: 16px;
}

.preview-url {
  height: auto;
  border: 0;
  background: rgba(255, 255, 255, 0.85) !important;
  color: #2e2e2e;
}

.osd-controls {
  position: fixed;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.osd-control {
  width: 40px;
  height: 40px;
  padding: 4px;
  margin-left: 0 !important;
  background: rgba(255, 255, 255, 0.8);
  border: none;
}

.osd-control:hover {
  background: rgba(217, 217, 217, 0.8);
}

.osd-control:not(:first-child) {
  margin-left: 0 !important;
  margin-top: 2px;
}

