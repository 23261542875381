.viewer--annotations-open .annotations-wrapper {
  transform: translateX(0);
}
.viewer:not(.viewer--annotations-open) .annotations-list,
.viewer:not(.viewer--annotations-open) .add-annotation {
  pointer-events: none;
  opacity: 0;
}

.viewer--annotations-open .show-annotations__icon {
  opacity: 0;
}

.viewer--annotations-open .hide-annotations__icon,
.viewer--annotations-open .hide-annotations__text {
  opacity: 1;
}

.annotations-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 320px;
  height: 100vh;
  padding: 16px;
  transform: translateX(-282px);
  transition: transform 0.3s;
  background: #e6e6e6;
  overflow: hidden;
  z-index: 10;
}

.annotations-wrapper .card-title {
  font-size: 23px;
}

.add-new-annotation {
  flex: 0 0 auto;
  align-self: flex-start;
  margin: 0 0 8px;
}

.add-annotation {
  margin: 16px -16px 0 -16px;
  border: 0;
  opacity: 1;
  transition: opacity 0.4s;
}

.annotations-list {
  flex: 1 1 auto;
  margin: 16px -16px 0;
  padding: 0;
  list-style-type: none;
  overflow: scroll;
  transition: opacity 0.4s;
}

.annotations-list li {
  font-size: 16px;
  padding: 16px;
  background: #ffffff;
  border-bottom: 1px solid #5c5c5c;
}

.annotation__actions {
  display: flex;
  justify-content: flex-end;
}

.annotation__actions .btn {
  margin-right: 8px;
}

.annotation__text {
  display: block;
  margin: 0 0 16px;
}

.toggle-annotations {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -16px -16px;
  z-index: 300;
}

.hide-annotations__text {
  opacity: 0;
  transition: opacity 0.4s;
}

.toggle-annotations__icon {
  margin-left: 8px;
  width: 20px;
  height: 20px;
  transition: opacity 0.4s;;
}

.show-annotations__icon {
  position: absolute;
  right: 10px;
  bottom: 8px;
  opacity: 1;
}