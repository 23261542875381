body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: "objektiv-mk3", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f2f2;
  color: #000;
}

a {
  color: #0071eb;
  text-decoration: underline;
}

.hidden {
  display: none;
}

/* Assistive technology only */
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}

.nowrap {
  white-space: nowrap;
}

*:not(.spinner-border) {
  border-radius: 0 !important;
}

.form-control {
  border: 1px solid #bab7b3;
}

.input-group .form-control {
  border: 1px solid #bab7b3;
  border-right: 0;
}

.was-validated .form-control:valid + .input-group-append ,
.form-control.is-valid + .input-group-append {
  border-color: #28a745;
}

.was-validated .form-control:invalid + .input-group-append ,
.form-control.is-invalid + .input-group-append {
  border-color: #dc3545;
}

.was-validated #terms:invalid {
  border-color: #dc3545;
}

.input-group-append {
  border: 1px solid #bab7b3;
  border-left: 0;
}

.input-group-text {
  border: 0;
}

.form-control {
  height: calc(1.5em + 0.75rem + 12px);
}

.btn--icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn--icon .btn__icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:hover {
  font-weight: 500;
  color: #000;
  background-color: #cce7e5;
  border-color: #cce7e5;
}

.btn-primary.disabled, .btn-primary:disabled {
  font-weight: 500;
  color: #000;
  background-color: rgba(204, 231, 229, 0.6);
  border-color: rgba(204, 231, 229, 0.6);
}

.btn-danger,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:hover {
  font-weight: 500;
  color: #000;
  background-color: #fededf;
  border-color: #fededf;
}

.btn-danger.disabled, .btn-danger:disabled {
  font-weight: 500;
  color: #000;
  background-color: rgba(254, 222, 223, 0.6);
  border-color: rgba(254, 222, 223, 0.6);
}


.card__header {
  font-size: 23px;
  font-weight: 400;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #000;
}

.image-upload-form {
  padding: 20px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.03);
}

.form-error-container {
  color: #dc3545;
}

.image-upload-form__success_msg {
  font-size: 1em;
}

.image-upload-form__success_msg.is-valid {
  color: #1f8135;
}

#intro {
  display: grid;
  grid-template-columns: [pad-left] 1fr [center] 10fr [pad-right] 1fr;
  grid-template-rows: [pad-top] 1fr [center] max-content [pad-bottom] 1fr;
  height: 100vh;
}

.intro-wrapper {
  position: relative;
  z-index: 100;
  grid-column: center;
  grid-row: center;
}

/* 700px */
@media (min-width: 43.75em) {
  #intro {
    grid-template-columns: [pad-left] 2fr [center] 8fr [pad-right] 2fr;
  }
}

/* 960px */
@media (min-width: 60em) {
  #intro {
    grid-template-columns: [pad-left] 3fr [center] 6fr [pad-right] 3fr;
  }
}

.intro__form {
  margin-top: 54px;
}

.was-validated .form-control:valid:not([required]) {
  border-color: #ced4da;
  padding-right: 0.75rem;
  background: none;
}

.was-validated .form-control {
  background-position: calc(100% - 10px) 50% !important;
}

.was-validated .form-control:valid:not([required]):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.toggle-annotations:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* #root {
  background: url('/DT707.jpg') center center / cover no-repeat;
} */

.image-credit {
  font-size: 12px;
}

.image-credit a {
  color: #0062cd;
}

.footer-align{
  text-align: center;
}

svg.icon {
  max-width: 100%;
  max-height: 100%;
}

.provider-logo {
  display: block;
  margin-bottom: 12px;
}

.provider-logo img {
  max-width: 100%;
}

.cookieConsent {
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  bottom: 0;
  z-index: 1000;
}

.cookieConsent__text {
  flex: 1 1 100%;
  font-size: 14px;
  margin-right: 16px;
}

.cookieConsent__button {
  flex: 1 1 auto;
  white-space: nowrap;
}

.email-form {
  font-size: 14px;
}

.terms-container label {
  margin-bottom: 0;
  display: inline-block;
}

input[type="checkbox"].big-checkbox{
  -webkit-appearance: none;
  border: 2px solid;
  padding: 9px;
  border-color: #2e2e2e;
  width: 26px;
  height: 26px;
}

input[type="checkbox"].big-checkbox:checked::after {
  content: "\2713";
  margin: 0 auto;
  display: block;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
}

input[type="checkbox"].big-checkbox:checked {
  background-color: #cce7e5;
  border-color: #cce7e5;
  padding: 0;
}
